body {
    padding-top: 50px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.editable-cell {
    border: 0;
    background-color: inherit;
}

.NFTCanvas {
    -webkit-transform : scale(0.33);
    transform: scale(0.33);
    -webkit-transform-origin : 0 0;
    transform-origin: 0 0;
    border: 1px solid black;
}

.react-bootstrap-drawer-toc-header {
    margin-top: 0 !important;
}

.react-bootstrap-drawer {
  top: 0rem !important;
}

.DropZone {
    height: 60px;
    background: lightgray;
    border: 1px solid black;
}

@font-face {
    font-family: "Fratres-BookRoman";
    src: url("https://www.givelaud.com/static/fonts/Fratres-BookRoman.woff") format("woff"),
    url("https://www.givelaud.com/static/fonts/Fratres-BookRoman.otf") format("opentype");
}

@font-face {
    font-family: "Fratres-BoldRoman";
    src: url("https://www.givelaud.com/static/fonts/Fratres-BoldRoman.woff") format("woff"),
    url("https://www.givelaud.com/static/fonts/Fratres-BoldRoman.otf") format("opentype");
}

.circle135 {
    clip-path: circle(135px at center);
}

.circle128 {
    clip-path: circle(128px at center);
}

.circle150 {
    clip-path: circle(128px at center);
}

.circularButton {
    clip-path: circle(16px at center);
}

.bg-light {
    background-color: #f8f9fa !important;
}