// Import Lato just to be safe
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

// change the theme
$theme-colors: (
  "primary":#6A2473,
  "danger": #ff9900,
  "info": #FDF8F5,
  "warning": #ffc107,
  "success": lightblue,
);

$colors: (
    "primary": purple,
    "danger": #ff9900,
    "info": #FDF8F5,
    "warning": #ffc107,
    "success": lightblue,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.card-header {
    color:  white;
    background-color:slategray;
    padding-bottom: 0
}

.modal-header {
  color: #FDF8F5;
  background-color: purple
}

button.close {
  color: #FDF8F5;
  background-color: purple
}

.btn-link {
  padding: 0;
  text-align: left !important;
}

.about-sectionFormat {
  position: relative;
  background-image: url('https://www.givelaud.com/static/media/headerSplash.webp');
  background-size: cover;
  width: auto;
  overflow-x: hidden !important;
  margin-left: 0px;
  margin-right: 0px;
}

.about-splashText {
  position: relative;
  font-family: 'Meriwheater', 'Georgia', serif;  
  font-style: normal;
  font-weight: bold;
  font-size:  clamp(2rem, 7vw, 3rem);
  color: #FFFFFF;
}

.about-bottompaneltext {
  font-family: 'Meriwheater', 'Georgia', serif;  
  font-style: normal;
  font-weight: bold;
  font-size:  clamp(1rem, 4vw, 2rem);
  color: #6A2473;
}

.about-appname {
  color: purple;
  font-family: 'Merriwheater', 'Georgia', serif;
  font-size: 96px;
  font-weight: bold
}

.about-tagline {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size:  clamp(18px, 4vw, 32px);
  color:white;
}

.about-quote {
  font-style: italic;
  color: darkgray;
}

.about-body {
  color: black;
  font-family: 'Lato', sans-serif;
  font-size: clamp(16px, 3vw, 28px);
}

.appname-color {
  color: purple
}

.gStarted-splashPanel {
    position: relative;
    background-image: url('https://www.givelaud.com/static/media/gettingStartedSplash.svg');
    background-size: cover;
    width: auto;
    overflow-x: hidden !important;
    margin-left: 0px;
    margin-right: 0px;
  }
.gStarted-splashText {
    position: relative;
    font-family: 'Meriwheater', 'Georgia', serif;  
    font-style: normal;
    font-weight: bold;
    font-size:  clamp(18px, 7vw, 48px);
    color: #334668;
  }

  .gStarted-tagline {
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    font-size:  clamp(16px, 4vw, 28px);
    color:#44444F;
  }
  .gStarted-stepheading {
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    font-size:  clamp(14px, 4vw, 22px);
    color:#44444F;
  }

  .accept-award-titleText {
    position: relative;
    font-family: 'Meriwheater', 'Georgia', serif;  
    font-style: normal;
    font-weight: bold;
    font-size:  clamp(18px, 7vw, 48px);
    color: white;
  }

  .acceptAward-Image {
    background-image: url('https://www.givelaud.com/static/media/headerSplash.webp');
    background-size: cover;
    overflow-x: hidden !important;
    margin-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .managedMinting-Title {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size:  clamp(24px, 4vw, 32px);
    color: purple;
  }

  .trophyLandingElementText {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
  }

  .engineeringLandingElementText {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: white;
  }

  .acceptAward-SolidColor {
    background-color: #D7F0EF;
    background-size: cover;
    overflow-x: hidden !important;
    margin-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .nftCodeDisplayFont {
    font-family: 'Courier', monospace;
  }